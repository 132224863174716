import React, { Component } from "react";
import axios from "axios";
import ApiURL from "../../api/ApiURL";
import cogoToast from "cogo-toast";
import { registerAllModules } from "handsontable/registry";
import LocalStorageHelper from "../../LocalStorageHelper/LocalStorageHelper";
import "handsontable/dist/handsontable.full.min.css";
import "../../assets/css/custom.css";


class URL extends Component {
  //constructor lifecycle event
  constructor(props) {
    super(props);
    //register Handsontable's modules
    registerAllModules();
    this.state = {
      showModal: false,
      showDeleteWarnModal: false,
      URLData: [],
      TCData: [],
      SPLData: [],
      urlId: 0,
      urlName: "",
      travelCircuitId: 0,
      specialistId: 0,
      userId: 0,
      userRole: '',
      isActive: false,
    };
  }
  //componentDidMount lifecycle event
  componentDidMount() {
    window.scroll(0, 0);
    var userData = JSON.parse(LocalStorageHelper.getUser());
    if (userData !== null && userData.UserId > 0) {
      this.setState({
        userId: userData.UserId,
        userRole: userData.RoleName,
        displayName: userData.DisplayName
      })
    }
    this.getAllURLData();
    this.getAllTCData();
    this.getAllSPLData();
  }
  //Data Reload
  handleReload = () => this.getAllURLData();
  //Open modal
  openModal = () => this.setState({ showModal: true });
  //Close modal
  closeModal = () => this.setState({ showModal: false });
  //Open Delete warning modal
  openWarnModal = () => this.setState({ showDeleteWarnModal: true });
  //Close Delete warning modal
  closeWarnModal = () => this.setState({ showDeleteWarnModal: false });
  //OnChangeHandler
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
  //Get all data
  getAllURLData = () => {
    axios
      .get(ApiURL.GetAllURL)
      .then((response) => {
        let respData = response.data;
        if (respData.StatusCode === 200) {
          var dataResult = respData.DataResult;
          this.setState({ URLData: dataResult });
        } else if (respData.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", {
            position: "bottom-center",
          });
        }
      })
      .catch((error) => {
        cogoToast.error("Request Failed! Please Try Again.", {
          position: "bottom-center",
        });
      });
  }
  //Get data by Id
  getURLById = (urlId) => {
    var self = this;
    axios
      .get(ApiURL.GetURLById(urlId))
      .then((response) => {
        // console.log(response);
        let respData = response.data;
        // console.log("the response is:",respData);
        if (respData.StatusCode === 200) {
          var dataResult = respData.DataResult;

          self.setState({
            urlId: dataResult.UrlId,
            urlName: dataResult.UrlName,
            travelCircuitId: dataResult.TravelCircuitId,
            specialistId: dataResult.SpecialistId,
            showModal: true,
          });
        } else if (respData.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", {
            position: "bottom-center",
          });
        }
      })
      .catch((error) => {
        cogoToast.error("Request Failed! Please Try Again.", {
          position: "bottom-center",
        });
      });
  }
  //Get all Travel Circuit data
  getAllTCData = () => {
    axios
      .get(ApiURL.GetAllTC)
      .then((response) => {
        let respData = response.data;
        if (respData.StatusCode === 200) {
          var dataResult = respData.DataResult;

          // console.log("dataResult: ", dataResult);
          var travelCircuit = [
            { TravelCircuitId: 0, TravelCircuitName: "-- Select --" },
          ];
          if (dataResult.length > 0) {
            for (var i = 0; i < dataResult.length; i++) {
              travelCircuit.push({
                TravelCircuitId: dataResult[i].TravelCircuitId,
                TravelCircuitName: dataResult[i].TravelCircuitName,
              });
            }
          }

          this.setState({ TCData: travelCircuit });
        } else if (respData.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", {
            position: "bottom-center",
          });
        }
      })
      .catch((error) => {
        cogoToast.error("Request Failed! Please Try Again.", {
          position: "bottom-center",
        });
      });
  }
  //Get all Specialist(name) data
  getAllSPLData = () => {
    axios
      .get(ApiURL.GetAllSPL)
      .then((response) => {
        let respData = response.data;
        if (respData.StatusCode === 200) {
          var dataResult = respData.DataResult;
          // console.log("dataResult: ", dataResult);
          var specialists = [
            { SpecialistId: 0, SpecialistName: "-- Select --" },
          ];
          if (dataResult.length > 0) {
            for (var i = 0; i < dataResult.length; i++) {
              specialists.push({
                SpecialistId: dataResult[i].SpecialistId,
                SpecialistName: dataResult[i].SpecialistName,
              });
            }
          }
          // console.log("specialists: ", specialists);
          this.setState({ SPLData: specialists });
        } else if (respData.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", {
            position: "bottom-center",
          });
        }
      })
      .catch((error) => {
        cogoToast.error("Request Failed! Please Try Again.", {
          position: "bottom-center",
        });
      });
  }
  //Handle Add new Record
  handleAddNew=()=>{
    this.resetInputFields();
    this.openModal();
  }
  //Reset all input fields into the modal
  resetInputFields = () => {
    this.setState({
      showModal: false,
      showDeleteWarnModal: false,
      urlId: 0,
      urlName: "",
      travelCircuitId: 0,
      specialistId: 0,
      isActive: false
    });
  }
  //Delete data by Id
  handleDelete = () => {
    let param = {
      urlId: this.state.urlId,
      isActive: this.state.isActive,
    };

    axios
      .post(ApiURL.DeleteURL, param)
      .then((response) => {
        let respData = response.data;

        if (respData.StatusCode === 200) {

          this.resetInputFields();
          //call data load
          this.closeWarnModal();
          this.closeModal();
          this.getAllURLData();
          //Show success message
          cogoToast.success("URL Deleted Successfully!", { position: "top-center" });

        } else if (respData.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", {
            position: "bottom-center",
          });
        }
      })
      .catch((error) => {
        console.error("Server Error:", error);
        cogoToast.error("Request Failed! Please Try Again.", {
          position: "bottom-center",
        });
      });
  }
  //Save dta
  handleSubmit = (e, urlId) => {
    e.preventDefault();

    //set the blank validation
    if (!this.state.urlName || !this.state.travelCircuitId || !this.state.specialistId) {
      cogoToast.error("Please fill all required fields.", { position: "bottom-center" });
      return;
    }

    let param = {
      urlId: this.state.urlId,
      urlName: this.state.urlName,
      travelCircuitId: parseInt(this.state.travelCircuitId),
      specialistId: parseInt(this.state.specialistId),
      isActive: true
    };

    axios
      .post(ApiURL.SaveURL, param)
      .then((response) => {
        console.log(response);
        let respData = response.data;
        if (respData.StatusCode === 200) {
          cogoToast.success("URL Saved Successfully!", { position: "bottom-center" });
          this.resetInputFields();
          this.getAllURLData();
        }
        else if (respData.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", { position: "bottom-center" });
        }
      })
      .catch((error) => {
        cogoToast.error("Request Failed! Please Try Again.", { position: "bottom-center" });
      });
  }
  //render lifecycle event.
  render() {
    var self = this;
    const { showModal, TCData, travelCircuitId, SPLData, specialistId, urlId, showDeleteWarnModal, URLData } = self.state;
    const urlListView = URLData.map((url, i) => (
      <tr key={i} >
        <td data-title="Select" className="text-center">
          <button
            className="btn btn-primary btn-sm text-white"
            style={{ height: "30px", width: "30px" }}
            onClick={() => self.getURLById(url.UrlId)}
          >
            <i className="fa-solid fa-pen-to-square"></i>
          </button>
        </td>
        <td data-title="URL" >{url.UrlName}</td>
        <td data-title="Travel Circuit"  >{url.TravelCircuitName}</td>
        <td data-title="Specialist Name" >{url.SpecialistName}</td>
        <td data-title="Email" align='center'>
          {
            url.IsActive ?
              <i className="fa-regular fa-square-check" style={{ fontSize: "20px" }}></i> :
              <i className="fa-regular fa-square-uncheck" style={{ fontSize: "20px" }}></i>
          }
        </td>
      </tr>
    ));

    return (
      <>
        {/* ------Start the main content ------------- */}
        <div className="container-fluid">
          <div className="card px-4 py-4">
            <div className="d-flex justify-content-end">
              <button
                type="button"
                onClick={this.handleReload}
                className="btn btn-info px-3 mx-3 "
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="Refresh"
              >
                <i className="fa-solid fa-arrows-rotate"></i>
              </button>
              <button
                onClick={this.handleAddNew}
                className="btn btn-primary px-3 cursor-pointer"
                data-toggle="tooltip "
                data-bs-placement="top"
                data-bs-title="Add"
              >
                <i className="fa-solid fa-plus"></i>
              </button>
            </div>
            <hr className="hr" />

            {/* grid table */}
            <div id="no-more-tables" className="tableContainer" >
              <table className="table table-bordered table-striped table-condensed cf " >
                <thead className="cf bg-primary text-white">
                  <tr>
                    <th className="text-center" style={{ minWidth: "40px" }}>Select</th>
                    <th className="text-center" style={{ minWidth: "150px" }}>URL</th>
                    <th className="text-center" style={{ minWidth: "150px" }}>Travel Circuit</th>
                    <th className="text-center" style={{ minWidth: "150px" }}>Specialist</th>
                    <th className="text-center">Active</th>
                  </tr>
                </thead>
                <tbody >{urlListView}</tbody>
              </table>
            </div>
          </div>
        </div>

        {/* <!----------------- modal section----------------> */}
        <div
          className={showModal === true ? "modal show-modal  modalWrapper" : "modal"}
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title ">
                  {urlId <= 0 ? `Add URL` : `Update URL`}
                </h1>
                <i className="fa-regular fa-circle-xmark text-danger" data-bs-dismiss="modal" onClick={this.closeModal}></i>
              </div>
              <div className="modal-body">
                <div className="row ">
                  <div className="col-12 mb-2 position-relative">
                    <label
                      htmlFor="validationCustom03"
                      className="form-label text-sm"
                      style={{ fontSize: "15px" }}
                    >
                      Url Name *
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="URL Name"
                      aria-label="url name"
                      name="urlName"
                      value={this.state.urlName}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label
                      htmlFor="tavelCircuit"
                      className="form-label text-sm"
                      style={{ fontSize: "15px" }}
                    >
                      Travel Circuit *
                    </label>
                    <select
                      className="form-control"
                      name="travelCircuitId"
                      value={travelCircuitId}
                      onChange={this.handleChange}
                      required
                    >
                      {TCData.map((item, index) => (
                        <option key={index} value={item.TravelCircuitId}>
                          {item.TravelCircuitName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label
                      htmlFor="specialistName"
                      className="form-label "
                      style={{ fontSize: "15px" }}
                    >
                      Specialist Name *
                    </label>
                    <select
                      className="form-control"
                      name="specialistId"
                      value={specialistId}
                      onChange={this.handleChange}
                      required
                    >
                      {SPLData.map((item, index) => (
                        <option key={index} value={item.SpecialistId}>
                          {item.SpecialistName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="modal-footer my-3">
                <button
                  type="submit"
                  className="btn btn-success button-box-shadow"
                  onClick={this.handleSubmit}
                >
                  <i className="fa-solid fa-circle-check"></i> Save
                </button>
                {
                  this.state.userRole === 'admin' &&
                  <button
                    type="button"
                    className={`btn btn-danger button-box-shadow curser-pointer ${urlId > 0 ? "" : "disabled"
                      }`}
                    onClick={() => {
                      urlId > 0 && this.openWarnModal();
                    }}
                  >
                    <i className="fa-solid fa-trash-can"></i> Delete
                  </button>
                }

                <button
                  type="button"
                  className="btn btn-secondary button-box-shadow"
                  data-bs-dismiss="modal"
                  onClick={this.closeModal}
                >
                  <i className="fa-solid fa-circle-xmark"></i> Close
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <!------DELETE Warning Modal--------> */}
        <div className={showDeleteWarnModal === true ? "modal show-modal " : "modal"}>
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content button-box-shadow">
              <div className="modal-body">
                <div className="alert alert-warning d-flex align-items-center" role="alert">
                  <i className="fa-solid fa-triangle-exclamation"></i>
                  &nbsp;
                  <div>
                    <strong>Are you sure</strong>, you want to <strong>Delete </strong> it?
                  </div>
                </div>
              </div>
              <div className="modal-footer ">
                <button
                  type="button"
                  className="btn btn-danger button-box-shadow btn-sm"
                  onClick={this.handleDelete}
                >
                  <i className="fa-solid fa-trash-can"></i> Delete
                </button>

                <button
                  type="button"
                  className="btn btn-secondary button-box-shadow btn-sm"
                  data-bs-dismiss="modal"
                  onClick={this.closeWarnModal}
                >
                  <i className="fa-solid fa-circle-xmark"></i> Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default URL;
